// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React, { useEffect, useState } from "react";

import { ExpandableSection, Spinner } from "@cloudscape-design/components";

export default function Chat({
	embedUrl,
	embedWidth = 600,
	embedHeight = 550,
	embedOffsetRightPc = 5,
	headerText = "Chat",
	headerInfo = "with us",
}) {
	const [isExpanded, setIsExpanded] = useState(true);
	const [isLoadingDelay, setIsLoadingDelay] = useState(true);

	const handleOnChange = (event) => {
		setIsExpanded(event.detail.expanded);
	};

	useEffect(() => {
		const loadingTimeout = setTimeout(() => {
			setIsLoadingDelay(false);
		}, 2000);

		return () => clearTimeout(loadingTimeout);
	}, [embedUrl]);

	return (
		<div
			style={{
				position: "fixed",
				bottom: isExpanded ? "-10px" : "-5px",
				right: `${embedOffsetRightPc}%`,
				zIndex: "1000",
			}}
		>
			<ExpandableSection
				aria-label="Chat section"
				defaultExpanded
				disableContentPaddings
				variant="container"
				headerInfo={headerInfo}
				headerText={headerText}
				onChange={handleOnChange}
			>
				{isLoadingDelay ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: embedHeight,
						}}
					>
						<Spinner size="large" />
					</div>
				) : (
					<iframe
						src={embedUrl}
						width={embedWidth}
						height={embedHeight}
						allowFullScreen
						style={{
							border: "none",
							overflow: "hidden",
						}}
						onLoad={() => setIsLoadingDelay(false)}
					></iframe>
				)}
			</ExpandableSection>
		</div>
	);
}




// // Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// // SPDX-License-Identifier: MIT-0
// import React, { useEffect, useState } from "react";
// import { ExpandableSection, Spinner } from "@cloudscape-design/components";
// import './chat.css'; // Import the CSS file

// export default function Chat({
// 	embedUrl,
// 	embedWidth = 600,
// 	embedHeight = 550,
// 	embedOffsetRightPc = 5,
// 	headerText = "Chat",
// 	headerInfo = "with us",
// }) {
// 	const [isExpanded, setIsExpanded] = useState(true);
// 	const [isLoadingDelay, setIsLoadingDelay] = useState(true);

// 	const handleOnChange = (event) => {
// 		setIsExpanded(event.detail.expanded);
// 	};

// 	useEffect(() => {
// 		const loadingTimeout = setTimeout(() => {
// 			setIsLoadingDelay(false);
// 		}, 2000);

// 		return () => clearTimeout(loadingTimeout);
// 	}, [embedUrl]);

// 	return (
// 		<div className="chat-container">
// 			<ExpandableSection
// 				aria-label="Chat section"
// 				defaultExpanded
// 				disableContentPaddings
// 				variant="container"
// 				headerInfo={headerInfo}
// 				headerText={headerText}
// 				onChange={handleOnChange}
// 			>
// 				{isLoadingDelay ? (
// 					<div
// 						style={{
// 							display: "flex",
// 							justifyContent: "center",
// 							alignItems: "center",
// 							height: embedHeight,
// 						}}
// 					>
// 						<Spinner size="large" />
// 					</div>
// 				) : (
// 					<iframe
// 						src={embedUrl}
// 						className="chat-iframe"
// 						allowFullScreen
// 						onLoad={() => setIsLoadingDelay(false)}
// 					></iframe>
// 				)}
// 			</ExpandableSection>
// 		</div>
// 	);
// }
